import React from "react";
import { PiMagicWand } from "react-icons/pi";
import SparkleBackground from "../../../Animations/SparkleBackground";
import AppSectionHeader from "../../reUsableComponents/AppSectionHeader";
import AppCard1 from "../../reUsableComponents/cards/AppCard1";

const AppPotential = () => {
  return (
    <section>
      <div className="max-w-5xl px-4 mx-auto sm:px-6">
        <div className="pt-32 pb-24">
          <SparkleBackground>
            <AppSectionHeader
              title="AutoFlow Studio"
              mainTitle="Unlock Your Potential"
              description="Create tests faster, run them instantly, share the reports automatically."
            />
          </SparkleBackground>
          <div className="max-w-4xl mx-auto">
            <div
              className={`relative w-full h-full bg-gradient-to-b from-primary-color-light/10 to-primary-color-light/5 rounded-3xl p-px  overflow-hidden`}
            >
              <AppCard1
                icon={<PiMagicWand className="text-3xl text-white" />}
                title={"All-In-One Platform"}
                description={
                  "From prototyping to production - test without switching tabs."
                }
                descWidth="w-full"
                content={
                  <img
                    src={
                      "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710852549/LandingPageAssets/eweiqzpyvj0pqreg0kkp.gif"
                    }
                    className="h-full w-full"
                    alt="all in one platform"
                  />
                }
                contentHeight={"h-auto"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppPotential;
