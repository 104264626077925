import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Session from "supertokens-web-js/recipe/session";
import { updateProfile } from "../../../api/services/apiService";
import { logo_square_without_name } from "../../../assets";
// import { account, home } from "../../constants/constants";
// import { home } from "../../../routes/routes";
import LoadingPage from "../../../auth/sdkLandingPage/LoadingPage";

const CreateAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: name,
      organisation: "",
    },
  });

  useEffect(() => {
    const checkisNameAvailable = async () => {
      if (await Session.doesSessionExist()) {
        try {
          let accessTokenPayload =
            await Session.getAccessTokenPayloadSecurely();
          let TokenFirstName =
            accessTokenPayload?.user_info?.first_name === null
              ? ""
              : accessTokenPayload?.user_info?.first_name;
          let TokenLastName =
            accessTokenPayload?.user_info?.last_name === null
              ? ""
              : accessTokenPayload?.user_info?.last_name;
          setName(TokenFirstName + " " + TokenLastName);
          setLoading(false);
          return true;
        } catch (error) {
          console.error("Error occurred while getting name", error);
          setLoading(false);
          return false;
        }
      } else {
        setLoading(false);
        return false;
      }
    };

    checkisNameAvailable();
  }, [name]);

  const handleRegister = (data) => {
    let payload = {
      name: data.name,
      organisation_name: data.organisation,
    };
    updateProfile(payload)
      .then((res) => {
        toast.success(res.detail);
        navigate("/sdk/account");
      })
      .catch((err) => console.log(err));
  };

  const handleLogOut = async () => {
    await Session.signOut();
    navigate("/sdk");
    window.location.reload();
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div
      className="bg-black h-screen w-screen flex flex-col items-center justify-start overflow-x-auto relative"
      style={{
        background:
          "linear-gradient(90deg, #2B2B02 0%, #0D1010 29%, #000000 48%, #000000 74%, #262B0C 100%)",
      }}
    >
      <div className="fixed top-5 right-5">
        <button
          className="btn btn-sm md:btn-md btn-warning"
          onClick={handleLogOut}
        >
          Sign Out
        </button>
      </div>
      <div className="flex flex-col items-center justify-center mt-32">
        <div className="flex justify-center flex-grow">
          <Link to={"/sdk"}>
            <img
              src={logo_square_without_name}
              alt="logo"
              className="object-cover h-20 w-20"
            />
          </Link>
        </div>
        <p className="text-white text-base md:text-xl font-primary font-semibold mt-4">
          Welcome to{" "}
          <span className="font-bold text-primary-color">Autoflow</span>
        </p>
      </div>

      <div className="w-[400px] p-8 sm:p-2 mt-10">
        <div className="text-center">
          <h1 className="font-secondary text-white/60">
            Please Provide Name and Organisation
          </h1>
        </div>

        <form action="" onSubmit={handleSubmit(handleRegister)}>
          <div className="flex flex-col justify-center mt-4">
            <label className="form-control w-full">
              <div className="label -mb-1">
                <span className={`label-text font-secondary text-white`}>
                  Name
                </span>
              </div>
              <input
                type="text"
                {...register("name", { required: "*Name is required" })}
                defaultValue={name || ""}
                placeholder="Enter your name"
                className={`input input-sm md:input-md bg-transparent border-white/50 outline-none focus:border-white/80 text-white/80 font-secondary`}
              />
              <div className="label">
                <span className="label-text-alt">
                  {errors && (
                    <p className="text-red-500">{errors.name?.message}</p>
                  )}
                </span>
              </div>
            </label>
          </div>

          <div className="flex flex-col justify-center">
            <label className="form-control w-full">
              <div className="label -mb-1">
                <span className={`label-text font-secondary text-white`}>
                  Organisation
                </span>
              </div>
              <input
                type="text"
                placeholder="Enter your organisation"
                {...register("organisation", {
                  required: "*Organisation is required",
                })}
                className={`input input-sm md:input-md bg-transparent border-white/50 outline-none focus:border-white/80 text-white/80 font-secondary`}
              />
              <div className="label">
                <span className="label-text-alt">
                  {errors && (
                    <p className="text-red-500">
                      {errors.organisation?.message}
                    </p>
                  )}
                </span>
              </div>
            </label>
          </div>

          <div className="text-center mt-10 w-full">
            <button className="btn btn-warning w-full">
              Complete Registration
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;
