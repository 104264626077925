import { styles } from "../styles/styles";

export const SectionHeader = ({ title, desc }) => {
  return (
    <>
      <div className="mx-2 mb-14 text-center">
        <div className={styles.mainTitle}>{title}</div>
        <p className={styles.mainDescription}>{desc}</p>
      </div>
    </>
  );
};
