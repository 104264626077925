import React, { useEffect, useState } from "react";
import { FaGithub } from "react-icons/fa";
import { FaBarsStaggered } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import AppMobileNav from "./AppMobileNav";
import {
  appDownloads,
  appHome,
  appPricing,
  sdkHome,
} from "../../../routes/routes";
import { logo } from "../../../assets";

const AppHeader = () => {
  const location = useLocation();
  const [scrolling, setScrolling] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed inset-x-0 w-full ${
        scrolling ? " bg-opacity-90 backdrop-blur-lg" : "bg-transparent"
      } transition-all duration-300 ease-in-out z-[999]`}
    >
      <div className="flex flex-row items-center justify-between sm:justify-around px-4 py-4 relative transition after:absolute after:inset-x-0 after:bottom-0 after:-z-10 after:h-px after:bg-gradient-to-r after:from-transparent after:via-zinc-500/20 sm:px-6">
        {/* Logo */}
        <div className="cursor-pointer relative  hover:scale-105 duration-200">
          <Link to={appHome}>
            <img src={logo} alt="Logo" className="h-16" />
          <div class="badge absolute bg-primary-color bottom-0 right-0 font-semibold">Studio</div>

          </Link>
        </div>

        {/* Navigation buttons */}
        <div className="hidden flex-shrink-0 flex-nowrap items-center justify-start rounded-full border border-zinc-300/10 p-1 sm:flex">
          <div className="relative flex items-center font-display gap-x-1">
            <Link to={appDownloads}>
              <button
                className={`relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-4 py-2 text-sm  transition hover:text-yellow-600/80 ${
                  location.pathname === appDownloads
                    ? "border-primary-color/20 text-yellow-600"
                    : "text-zinc-400"
                }`}
              >
                Download
              </button>
            </Link>
             <Link to={appPricing}>
              <button
                className={`relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-4 py-2 text-sm  transition hover:text-yellow-600/80 ${
                  location.pathname === appPricing
                    ? "border-primary-color/20 text-yellow-600"
                    : "text-zinc-400"
                }`}
              >
                Pricing
              </button>
            </Link>
            <button className={`relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-4 py-2 text-sm  transition hover:text-yellow-600/80 text-zinc-400`}>
                <a href="https://docs.autoflow.tools" target="_blank">Documentation</a>
              </button>
            <button className="relative z-10 flex flex-shrink-0  items-center rounded-full border border-transparent px-4 py-2 text-sm text-zinc-400 transition  cursor-not-allowed">
              Changelog{" "}
              <span className="ml-2 mt-0.5 text-xs border border-secondary-color-light/10 rounded-md px-1">
                Soon!
              </span>
            </button>
           
            {/* <button className="relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-white/20 px-4 py-2 text-sm text-zinc-400 transition hover:text-zinc-100/80">
              Docs
            </button> */}
            <div className="flex overflow-hidden rounded-full">
              <Link to={sdkHome} target="_blank">
                <div className="flex flex-shrink-0 items-center justify-center border border-zinc-700 rounded-full bg-gradient-to-r from-zinc-600/30 via-zinc-700/30 to-zinc-800/30 px-4 py-2 text-zinc-50 text-sm backdrop-blur-md transition hover:from-zinc-600/50 hover:via-zinc-700/50 hover:to-zinc-800/50">
                  SDK
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Sign In button */}
        <div className="hidden sm:flex flex-row items-center">
          <a
            href="https://github.com/AutoFlowLabs/autoflow"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-ghost text-white font-display font-bold">
              <FaGithub className="text-lg" />
            </button>
          </a>
          <Link to={appDownloads}>
            <button
              className="relative inline-flex flex-shrink-0 items-center justify-center rounded-xl border border-zinc-300/10 bg-zinc-600/20 px-3 py-2 text-sm font-display font-normal transition text-secondary-color-light/80  hover:border-zinc-500/80 hover:bg-zinc-600/30
            "
            >
              Download App
            </button>
          </Link>
        </div>
        <div className="flex sm:hidden">
          <button
            className="btn btn-ghost"
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <FaBarsStaggered className="text-2xl text-white" />
          </button>
          <AppMobileNav
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            location={location}
          />
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
