import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios"; // Import Axios for API calls
import PasswordInput from "./components/PasswordInput";
import { logo_square_without_name } from "../../assets";
import {
    emailPasswordSignUp,
    getAuthorisationURLWithQueryParamsAndSetState,
} from "supertokens-web-js/recipe/thirdpartyemailpassword";

function Invitation() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [inviteData, setInviteData] = useState(null);

    useEffect(() => {
        const inviteId = new URLSearchParams(location.search).get("invite-id");
        if (inviteId) {
            // Make API call to fetch invite details
            axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/auth/accept-invite`, { invite_id: inviteId }) // Update the URL
                .then(response => {
                    if (response.data.invite_id) {
                        setInviteData(response.data);
                        setLoading(false);
                    } else {
                        toast.error("Invalid invite data received.");
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error("Error fetching invite details:", error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [location.search]);

    const handleSignUp = async (data) => {
        // Handle sign-up logic
        setLoading(true);
        try {
            const formFields = [
                {
                    id: "email",
                    value: data.email,
                },
                {
                    id: "password",
                    value: data.password,
                },
                {
                    id: "organisation_name",
                    value: data.company_name,
                },
                {
                    id: "name",
                    value: `${data.first_name} ${data.last_name}`,
                },
                {
                    id: "organisation_id",
                    value: `${inviteData.invited_to_organisation}`,
                },
                {
                    id: 'client',
                    value: 'app'
                }
            ];

            console.log("formFields: ", formFields);
            let response = await emailPasswordSignUp({
                formFields: formFields
            });
            if (response.status === "FIELD_ERROR") {
                setLoading(false);
                response.formFields.forEach((formField) => {
                    if (formField.id === "email") {
                        toast.error(formField.error);
                    } else if (formField.id === "password") {
                        toast.error(formField.error);
                    }
                });
            } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
                setLoading(false);
            } else {
                setLoading(false);
                navigate('/sdk/account-created', { state: { inviteId: inviteData.invite_id, organisation: inviteData.invited_to_organisation_name, email: inviteData.invitee_email } });
            }
        } catch (err) {
            setLoading(false);
            if (err.isSuperTokensGeneralError === true) {
                toast.error(err.message);
            } else if (err.status === 409) {
                toast.error("Email already exists!");
            } else {
                toast.error("Oops! Something went wrong.");
            }
        }
    };

    return (
        <div className="relative h-screen w-screen flex items-center justify-center bg-primary-color-dark overflow-hidden">

            <div
                className="flex items-center justify-center w-[200px] h-[200px] sm:w-[500px] sm:h-[500px] md:w-[650px] md:h-[650px] rounded-full"
                style={{
                    background:
                        "radial-gradient(circle at 50% 50%, #997529 0%, #985F2A 0%, #A1792D 0%, #985F2A 26%, #966125 41%, #825520 54%, #684323 71%, #2D2A2A 82%, #2D2A2A 100%)",
                    boxShadow: "4px 6px 95px 29px #8f681b inset",
                    animation: "colorChange1 5s infinite alternate",
                }}
            />

            {loading ? (
                <div className="text-white text-base md:text-lg font-display mt-4">Loading...</div>
            ) : inviteData ? (
                inviteData.status === "NEW_USER" ? (

                    <div className="absolute flex flex-row justify-end h-full w-screen">
                        <div className="h-full w-full sm:w-1/2 backdrop-blur-[45px]">
                            <div className="relative flex flex-row items-center justify-center h-full w-full overflow-y-scroll scrollable-content">
                                <div className="flex flex-col items-center justify-center">
                                    <div className="flex justify-center flex-grow">
                                        <Link to={"/sdk"}>
                                            <img
                                                src={logo_square_without_name}
                                                alt="logo"
                                                className="object-cover h-20 w-20"
                                            />
                                        </Link>
                                    </div>
                                    <p className="text-white text-base md:text-lg font-display mt-4">
                                        Welcome to
                                        <span className="border ml-2 px-2 py-[2px] rounded-xl bg-secondary-color font-bold heading shadow-top-left-bottom-right">
                                            Autoflow
                                        </span>
                                    </p>
                                    <h1 className="text-white text-3xl md:text-4xl mt-5 md:mt-8 font-display font-bold ">
                                        {inviteData.invited_by_user_name} invited you!
                                    </h1>
                                    <h2 className="text-white">
                                        Create an account to start working with{" "}
                                        {inviteData.invited_by_user_name} in{" "}
                                        {inviteData.invited_to_organisation_name}
                                    </h2>

                                    <form action="" onSubmit={handleSubmit(handleSignUp)}>
                                        {setValue("email", inviteData.invitee_email)}
                                        {setValue("company_name", inviteData.invited_to_organisation_name)}

                                        <div className="flex flex-col md:flex-row md:items-center justify-center md:gap-4 mt-6">
                                            <div className="flex flex-col justify-center -mb-2">
                                                <label className="form-control w-max-w-xs">
                                                    <div className="label -mb-1">
                                                        <span className="label-text font-secondary text-white/70">
                                                            First Name
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter your First Name"
                                                        {...register("first_name", {
                                                            required: "*First Name is required",
                                                        })}
                                                        className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[250px] ${errors?.first_name &&
                                                            "border-red-500 focus:border-red-500"
                                                            }`}
                                                    />
                                                    <div className="label">
                                                        <span className="label-text-alt">
                                                            {errors.first_name && (
                                                                <p className="text-red-500">
                                                                    {errors.first_name.message}
                                                                </p>
                                                            )}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="flex flex-col justify-center -mb-2">
                                                <label className="form-control w-full max-w-xs">
                                                    <div className="label -mb-1">
                                                        <span className="label-text font-secondary text-white/70">
                                                            Last Name
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter your Last Name"
                                                        {...register("last_name", {
                                                            required: "*Last Name is required",
                                                        })}
                                                        className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[250px] ${errors?.last_name &&
                                                            "border-red-500 focus:border-red-500"
                                                            }`}
                                                    />
                                                    <div className="label">
                                                        <span className="label-text-alt">
                                                            {errors.last_name && (
                                                                <p className="text-red-500">
                                                                    {errors.last_name.message}
                                                                </p>
                                                            )}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="flex flex-col justify-center -mb-2">
                                            <label className="form-control w-full max-w-xs">
                                                <div className="label -mb-1">
                                                    <span className="label-text font-secondary text-white/70">
                                                        Work Email
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    {...register("email", {
                                                        required: "*Work Email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: "Invalid email address",
                                                        },
                                                    })}
                                                    className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[515px] ${errors?.email && "border-red-500 focus:border-red-500"
                                                        }`}
                                                    disabled
                                                    style={{ backgroundColor: "transparent", color: "white" }} // Add custom styles here

                                                />
                                                <div className="label">
                                                    <span className="label-text-alt">
                                                        {errors.email && (
                                                            <p className="text-red-500">{errors.email.message}</p>
                                                        )}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>

                                        <div className="flex flex-col justify-center -mb-2">
                                            <label className="form-control w-full max-w-xs">
                                                <div className="label -mb-1">
                                                    <span className="label-text font-secondary text-white/70">
                                                        Company Name
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Enter your Company Name"
                                                    {...register("company_name", {
                                                        required: "*Company Name is required",
                                                    })}
                                                    className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[515px] ${errors?.company_name && "border-red-500 focus:border-red-500"
                                                        }`}
                                                    disabled
                                                    style={{ backgroundColor: "transparent", color: "white" }} // Add custom styles here

                                                />
                                                <div className="label">
                                                    <span className="label-text-alt">
                                                        {errors.company_name && (
                                                            <p className="text-red-500">{errors.company_name.message}</p>
                                                        )}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>

                                        <PasswordInput
                                            inputWidth="w-[350px] md:w-[515px]"
                                            register={register("password", {
                                                required: "*Password is required",
                                                minLength: {
                                                    value: 5,
                                                    message: "Password must be at least 5 characters long",
                                                },
                                            })}
                                            errors={errors?.password}
                                        />

                                        <div className="mt-6">
                                            <button
                                                type="submit"
                                                className={`btn btn-sm md:btn-md bg-primary-color border-none outline-none hover:bg-primary-color/85 w-[350px] md:w-[515px] font-secondary text-base ${loading && "btn-disabled"
                                                    }`}
                                            >
                                                {loading ? (
                                                    <span className="loading loading-bars loading-sm md:loading-lg text-warning"></span>
                                                ) : (
                                                    "Create Account"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : inviteData.status === "EXISTING_USER_ORGANISATION_UPDATED" ? (
                    <div className="text-white">
                        You are now part of the {inviteData.invited_to_organisation_name}. Now you can
                        login to the app with your credentials.
                    </div>
                ) : (
                    <div className="text-white">
                        {inviteData.status === "ALREADY_MEMBER_OF_ORGANISATION"
                            ? `You are already part of this ${inviteData.invited_to_organisation_name}. You can login to the app with your credentials.`
                            : "Sorry! Can't able to accept the invite"}
                    </div>
                )
            ) : (
                <div className="text-white">Invalid invitation data.</div>
            )}
        </div>
    );
}

export default Invitation;