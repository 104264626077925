import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { appTestimonialsData } from "../../../../utils/Data";
import SparkleBackground from "../../../Animations/SparkleBackground";

const AppTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === appTestimonialsData.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [appTestimonialsData.length]);

  const handleButtonClick = (index) => {
    setCurrentIndex(index);
  };

  const rotateVariants = {
    visible: { opacity: 1, rotate: 0 },
    hidden: { opacity: 0, rotate: 0 },
  };

  return (
    <section className="overflow-hidden">
      <SparkleBackground>
        <div className="mx-auto max-w-4xl px-4 sm:px-6">
          <div className="relative pb-12 md:pb-16">
            <div className="text-center">
              {appTestimonialsData.map((data, index) => (
                <div
                  key={index}
                  className={index === currentIndex ? "" : "hidden"}
                >
                  <div
                    className={`relative h-32 [mask-image:_linear-gradient(0deg,_transparent,_theme(colors.zinc.500)_40%,_theme(colors.zinc.500))]`}
                  >
                    <div className="before:pointer-events-none  left-1/2 top-0 -z-10 h-[480px] -translate-x-1/2 absolute inset-0 rounded-full bg-gradient-to-b from-zinc-500/30 to-20% bg-black/50">
                      <div className={`absolute inset-0 -z-10 h-full`}>
                        {" "}
                        <motion.div
                          animate={
                            index === currentIndex ? "visible" : "hidden"
                          }
                          variants={rotateVariants}
                          transition={{ duration: 2 }}
                        >
                          <img
                            src={appTestimonialsData[currentIndex].img}
                            alt=""
                            className="relative left-1/2 top-11 h-16 w-16 -translate-x-1/2 rounded-full border border-zinc-500/20 p-1"
                          />
                        </motion.div>
                      </div>
                    </div>
                  </div>
                  <div className="relative mb-10 flex flex-col items-center">
                    <div className="block max-w-max bg-gradient-to-r from-white via-white/80 to-white/30 bg-clip-text text-xl font-semibold text-transparent">
                      {appTestimonialsData[currentIndex].testimonial}
                    </div>
                  </div>
                </div>
              ))}

              <div className="-m-1.5 flex flex-wrap justify-center">
                {appTestimonialsData.map((data, index) => {
                  return (
                    <button
                      key={index}
                      className={`relative m-1.5 inline-flex items-center justify-center rounded-full border border-transparent px-3 py-1 text-xs text-zinc-200 transition [background:linear-gradient(theme(colors.zinc.950),_theme(colors.zinc.950))_padding-box,_conic-gradient(theme(colors.zinc.400),_theme(colors.zinc.700)_25%,_theme(colors.zinc.700)_75%,_theme(colors.zinc.400)_100%)_border-box]  ${
                        index === currentIndex
                          ? "opacity-100"
                          : "opacity-50 hover:opacity-80"
                      } shadow-xl shadow-zinc-950/50`}
                      onClick={() => handleButtonClick(index)}
                    >
                      <span className="relative">
                        <span className="text-zinc-50">{data.name}</span>
                        <span className="ml-2 text-zinc-500">
                          {data.company}
                        </span>
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </SparkleBackground>
    </section>
  );
};

export default AppTestimonials;
