import React, { useEffect } from "react";
import { ReactCompareSlider } from "react-compare-slider";
import { MdArrowForwardIos, MdContentCopy } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Typed from "typed.js";
import { postRefCheck } from "../../../../../api/services/apiService";
import { sdkSignUp } from "../../../../../routes/routes";
import { codeData } from "../../data";

const Hero = () => {
  useEffect(() => {
    const options = {
      strings: ["Developers.", "QA Engineers.", "Product Owners.", "Everyone."],
      typeSpeed: 90,
      backSpeed: 90,
      loop: true,
      showCursor: false,
    };
    const typed = new Typed(".typed-text", options);
    return () => {
      typed.destroy();
    };
  }, []);
  const isMediumScreenOrAbove = useMediaQuery({ minWidth: 1050 });
  const handlecopy = () => {
    navigator.clipboard.writeText("npm i @autoflowlabs/playwright -D");
    let payload = {
      ref: "copiedNPM",
    };
    postRefCheck(payload)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };
  return (
    <section
      className="relative h-[1000px] sm:h-[1100px] md:h-[1300px] overflow-hidden pt-48 after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30 after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:z-50"
      id="land"
    >
      <div className="absolute flex flex-col items-center w-full h-full z-[500]">
        <p className="hero-text font-primary font">
          The UI Testing <br />
          Library for{" "}
          <span
            className="typewrite text-primary-color bg-clip-text"
            data-period="2000"
            data-type='[ "Developers", "QA Engineers", "Product Owners", "Everyone"]'
          />
          <span className="typed-text text-yellow-500"></span>
        </p>
        <p className="text-gray-300 text-center mt-4 text-xs md:text-base font-semibold font-secondary secondary-heading">
          Write AI powered intelligent end-to-end tests with{" "}
          <br className="flex sm:hidden" />
          AutoFlow's testing library
        </p>
        <div className="flex flex-col sm:flex-row items-center mt-8 space-x-1 sm:space-x-4 gap-4">
          <Link to={sdkSignUp}>
            <button className="btn btn-sm sm:btn-md btn-warning text-secondary-color-dark sm:text-base font-secondary">
              Get Beta Access
            </button>
          </Link>
          <div className="relative flex flex-row items-center bg-gray-800/70 font-secondary font-light px-4 py-[6px] sm:py-[10px] space-x-4 text-white border border-primary-color shadow-top-left-bottom-right rounded">
            <MdArrowForwardIos className="text-primary-color text-xs" />
            <p className="mb-[2px] sm:mb-[1px] pr-4 sm:pr-8 py-[2px] text-xs sm:text-base text-white/75">{`npm i @autoflowlabs/playwright -D`}</p>
            <button
              onClick={handlecopy}
              className="absolute top-[5px] right-1 text-white bg-transparent hover:text-primary-color"
            >
              <MdContentCopy className="text-sm sm:text-base" />
            </button>
          </div>
        </div>
        <div className="flex flex-row mt-[59px] font-secondary md:space-x-20">
          <div className="mx-auto w-40 md:w-80 text-start flex items-center space-x-1 md:space-x-2">
            <svg
              className="text-white/50 inline-block w-10 md:w-20 rtl:[transform:rotateY(180deg)] transform -scale-x-100"
              viewBox="0 0 45 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.50244C23.4 -1.69756 38.3333 11.1691 43 18.0024M43 18.0024L38 17.0024M43 18.0024V13.0024"
                stroke="currentColor"
              ></path>
            </svg>
            <span className="text-white/60 text-[10px] md:text-base inline-block mb-5 md:mb-8 font-light font-secondary -rotate-3 md:rotate-1">
              Traditional test case <br /> (150+ lines!)
            </span>
          </div>

          <div className="mx-auto w-50 md:w-60 text-start flex items-center space-x-1 md:space-x-2">
            <span className="text-primary-color text-[10px] md:text-base inline-block mb-5 md:mb-8 font-bold font-primary rotate-3 md:-rotate-1">
              AutoFlow Powered <br className="flex md:hidden" /> Test Case (~6
              lines)
            </span>
            <svg
              className="text-primary-color inline-block w-10 md:w-28 rtl:[transform:rotateY(180deg)]"
              viewBox="0 0 45 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.50244C23.4 -1.69756 38.3333 11.1691 43 18.0024M43 18.0024L38 17.0024M43 18.0024V13.0024"
                stroke="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <div className="px-2 sm:mx-auto h-[450px] sm:h-[500px] md:h-[700px] w-p[]  md:w-[1100px] lg:w-[1200px] rounded overflow-hidden">
          <ReactCompareSlider
            boundsPadding={isMediumScreenOrAbove ? 360 : 0}
            position={30}
            onlyHandleDraggable={true}
            className="w-full h-full"
            itemOne={
              <div className="p-2 relative">
                <div className="mockup-code w-[370px] sm:w-[700px] md:w-[830px] h-[400px] sm:h-[450px] md:h-[650px] md:rounded-r-none overflow-auto ">
                  {codeData[8].heroDiffCode1.code.map((code, index) => (
                    <div
                      key={index + 1}
                      className="code-block scrollable-content"
                    >
                      <pre
                        data-prefix={index + 1}
                        className="text-[10px] md:text-xs"
                      >
                        <code className="text-[10px] md:text-xs">{code}</code>
                      </pre>
                    </div>
                  ))}
                </div>
              </div>
            }
            itemTwo={
              <div className="p-2 md:pl-[360px]">
                <div className="mockup-code w-[370px] sm:w-[700px] md:w-[800px]  h-[400px] sm:h-[450px] md:h-[650px] bg-[#F4AC38] text-black  overflow-auto scrollable-content md:rounded-l-none">
                  {codeData[9].heroDiffCode2.code.map((code, index) => (
                    <div key={index + 1} className="code-block">
                      <pre
                        data-prefix={index + 1}
                        className="text-[10px] md:text-base"
                      >
                        <code className="text-[10px] md:text-base">{code}</code>
                      </pre>
                    </div>
                  ))}
                </div>
              </div>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
