import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { IoIosCopy } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { generateApiKeys, getApiKeys } from "../../../api/services/apiService";

function Apikeys() {
  const [data, setData] = useState([]);
  const [apiName, setApiName] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState("");
  useEffect(() => {
    setLoading(true);
    getApiKeys()
      .then((res) => {
        if (res?.results?.length >= 0) {
          setLoading(false);
          setData(res?.results);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [apiKeys]);

  const handleChange = (e) => {
    setApiName(e.target.value);
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKeys);
    setToggle(true);
  };

  const generateApi = (e) => {
    let payload = {
      name: apiName,
    };
    setLoading(true);
    generateApiKeys(payload)
      .then((res) => {
        if (res?.apiKey) {
          setApiKeys(res?.apiKey);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setApiKeys("");
    setToggle(false);
  };
  const handleModal = (e) => {
    let length = 6;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setApiName(result);
    document.getElementById("my_modal_1").showModal();
  };

  return (
    <div className="flex flex-col space-y-10 mt-10 bg-black pt-5 px-2 sm:px-10 w-full font-secondary">
      <div className="pb-10 z-[800] border border-[#5352529a] bg-black rounded-lg h-auto">
        <div className="p-4 md:p-10">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-white text-start text-xl md:text-3xl font-primary font-bold">
              Your{" "}
              <span className="underline decoration-2 md:decoration-4 decoration-primary-color underline-offset-4">
                API Keys
              </span>
            </h1>
            <button
              className="btn btn-xs -mb-16 md:-mb-14 sm:btn-sm md:btn-md btn-warning font-secondary font-bold text-[10px] md:text-base"
              onClick={handleModal}
            >
              Create API Keys
            </button>
          </div>

          <div className="mt-10 w-full">
            <div className="overflow-x-auto bg-secondary-color-dark border border-white/50 rounded-xl">
              <table className="table w-full min-w-96 overflow-x-scroll">
                <thead className="border-none text-primary-color font-primary font-bold md:text-base">
                  <tr className="border border-white/0">
                    <th></th>
                    <th>Name</th>
                    <th>Api keys</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody className="text-white font-secondary font-light">
                  {loading ? (
                    <tr>
                      <th></th>
                      <th>
                        <span className="loading loading-bars loading-lg text-warning ml-2"></span>
                      </th>
                    </tr>
                  ) : data?.length > 0 ? (
                    data?.map((item, i) => {
                      return (
                        <tr
                          className="border-none hover:bg-white/5 text-[10px] md:text-sm"
                          key={i}
                        >
                          <th>{i + 1}</th>
                          <th>{item.name}</th>
                          <td>{item.keyValue}</td>
                          <td>{item.createdBy}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-none text-white">
                      <th></th>
                      <th className="">No Data Found</th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end pt-4">
              <div className="join grid grid-cols-2">
                <button className="join-item btn btn-outline text-white">
                  Previous page
                </button>
                <button className="join-item btn btn-outline text-white">
                  Next
                </button>
              </div>
            </div>

            <dialog id="my_modal_1" className="modal">
              <div className="modal-box bg-[#221F1F] text-white card shadow-xl shadow-primary-color/25">
                <p className="font-primary font-bold text-primary-color capitalize text-xl md:text-2xl">
                  Generate API Keys
                </p>
                <form method="dialog">
                  <button
                    className="btn btn-xs sm:btn-sm btn-circle btn-neutral absolute right-2 top-3"
                    onClick={handleClose}
                  >
                    <IoCloseSharp className="text-lg sm:text-2xl text-white" />
                  </button>
                </form>
                <div className="flex py-5 border-primary-color text-primary-color ">
                  <div className="flex flex-col justify-center w-full">
                    <p className="font-primary capitalize text-white/70 text-sm sm:text-base">
                      Give a nickname to your API Key
                    </p>

                    <div className="flex flex-col justify-center w-full">
                      <label className="form-control w-full">
                        <div className="label -mb-1"></div>
                        <input
                          type="Text"
                          value={apiName}
                          onChange={(e) => handleChange(e)}
                          placeholder="Service key"
                          className={`input input-sm md:input-md bg-transparent border-white/50 outline-none focus:border-white/50 text-white font-secondary`}
                        />
                      </label>
                    </div>

                    {!apiKeys && (
                      <div className="pt-4 text-end">
                        <button
                          className="btn btn-sm sm:btn-md btn-warning font-secondary font-bold"
                          disabled={apiKeys ? true : false}
                          onClick={generateApi}
                        >
                          Generate API key
                          {loading && (
                            <span className="loading loading-spinner"></span>
                          )}
                        </button>
                      </div>
                    )}

                    {apiKeys && (
                      <div className="px-2">
                        <p className="font-primary font-bold text-white mt-5 capitalize">
                          api key
                        </p>

                        <div className="join w-full mt-1">
                          <input
                            type="Text"
                            value={apiKeys}
                            readOnly="true"
                            className={`input input-sm md:input-md bg-transparent border-white/50 outline-none rounded-r-none w-full focus:border-white/50 text-white font-secondary`}
                          />

                          <button
                            className={`btn btn-sm md:btn-md rounded-l-none ${
                              toggle ? "btn-success" : "btn-warning"
                            } `}
                            onClick={copyToClipboard}
                          >
                            {toggle ? (
                              <IoIosCopy className="md:text-xl" />
                            ) : (
                              <FaRegCopy className="md:text-xl" />
                            )}
                          </button>
                        </div>
                        <div>
                          <p className="text-primary-color text-xs m-1 font-secondary font-semibold">
                            You can only see this key once. Make sure to copy it
                            now and keep it safe.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <form method="dialog" className="modal-backdrop">
                <button onClick={handleClose}>close</button>
              </form>
            </dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apikeys;
