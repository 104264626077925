import React from "react";
import { Link } from "react-router-dom";

const PlanCard = ({
  title,
  description,
  price,
  tenure,
  invocationsUsed,
  totalInvocations,
  handleClick,
  billing_cycle,
  next_billing_at,
  update_payment_url,
  cancel_subscription_url,
}) => {
  return (
    <div className="bg-black border border-gray-800 shadow-sm shadow-slate-700 sm:w-[500px] rounded-lg p-4 text-white">
      <div className="flex flex-col md:flex-row items-start justify-between mb-4">
        <div>
          <h1 className="text-lg md:text-2xl font-primary font-bold text-primary-color">
            {title}
          </h1>
          <p className="text-[10px] md:text-sm text-white/80 mt-1">
            {description}
          </p>
        </div>
        <div className="my-4 md:mt-0">
          <p className="flex flex-col md:flex-row md:items-end gap-x-1">
            <span className="text-xl font-primary font-bold">{price}</span>{" "}
            <span className="font-secondary text-white/70">/{tenure}</span>
          </p>
        </div>
      </div>
      <div className="">
        <div className="flex flex-col items-start">
          <div className="font-primary font-bold text-sm md:text-base">
            {invocationsUsed} / {totalInvocations} invocations
          </div>
          <input
            type="range"
            min={0}
            max="1000"
            value={invocationsUsed}
            className="range range-xs range-warning mt-2 bg-white/20"
          />
        </div>
        <div className="mt-6 text-end">
          {title === "Free Plan" ? (
            <button
              onClick={handleClick}
              className="btn btn-sm btn-warning font-secondary"
            >
              Upgrade
            </button>
          ) : (
            <Link
              target="_blank"
              to={cancel_subscription_url}
              className="btn btn-sm btn-error font-secondary"
            >
              Cancel Subscription
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
