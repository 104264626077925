import React, { useEffect } from "react";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import AppTestimonials from "../../../components/AppLandingPageComponents/landingPageComponents/AppTestimonials";
import SparkleBackground from "../../../components/Animations/SparkleBackground";
import AppSectionHeader from "../../../components/AppLandingPageComponents/reUsableComponents/AppSectionHeader";
import AppBannerPage from "../../../components/AppLandingPageComponents/landingPageComponents/AppBannerPage";
import { useNavigate } from "react-router-dom";
import { appSubmitForm } from "../../../routes/routes";

const PricingPage = () => {

  useEffect(() => {
    document.title = "Plans & Pricing | AutoFlow Studio";
  }, [])

  const navigate = useNavigate();
  const handlePriceSubmit = (priceTitle) => {
    const data = {
      title: "Sales",
      description: "Fill the form below to get exclusive price from our sales team",
      src: `https://tally.so/embed/3EXQ5X?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1?tier=${priceTitle}`,
    };
    navigate(appSubmitForm, { state: { data: data } });
  };
  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative mx-auto max-w-6xl px-4 sm:px-6">
          <div className="pb-24 pt-32">
            <SparkleBackground>
              <AppSectionHeader
                title="Pricing"
                mainTitle="Affordable Plans for Everyone"
                description="We offer paid plans with additional features and support."
              />
            </SparkleBackground>

            <div
              className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-6xl 
            lg:grid-cols-3"
            >
              {/* card1 */}
              <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none rounded-3xl p-8 ring-1 ring-zinc-800 backdrop-blur-md">
                <h4 className="text-zinc-400 text-3xl font-display font-semibold leading-loose">
                  Basic{" "}
                  <span className="text-sm text-primary-color-light/70 font-normal">
                    (For startups)
                  </span>
                </h4>
                <div className="mt-4 flex items-baseline gap-x-2">
                  <h1 className="text-zinc-400 text-5xl font-semibold tracking-tight">
                    $250
                  </h1>
                  <span className="text-zinc-500 font-heading">/ month</span>
                </div>
                <p className="text-zinc-500 mt-6 text-sm">
                  Everything you need to get started with testing, perfect for
                  individuals and small teams.
                </p>
                <ul className="text-zinc-500 mt-8 space-y-3 text-sm font-display">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> 1 workspace
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    500 AI Suggestions
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    1000 AI Steps
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    100 Tests
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    1000 Flow Steps
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />2 Test Suites
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    24/7 Support
                  </li>
                </ul>
                <button
                  onClick={() => handlePriceSubmit("Basic")}
                  className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                >
                  Contact Sales
                </button>
              </div>

              {/* card2 */}
              <div className="relative bg-primary-color-light/80 shadow-2xl rounded-3xl p-8 py-10 ring-1 ring-zinc-800 backdrop-blur-md z-20">
                <div className="flex flex-row items-center justify-between">
                  <h1 className="text-zinc-950 text-3xl font-display font-semibold leading-loose">
                    Pro{" "}
                    <span className="text-sm text-primary-color-dark font-normal">
                      (For SMEs)
                    </span>
                  </h1>

                  <div className="absolute top-6 right-6 badge badge-warning badge-md gap-2 font-heading font-medium">
                    ✦ Most popular
                  </div>
                </div>
                <div className="mt-4 flex items-baseline gap-x-2">
                  <h1 className="text-zinc-950 text-5xl font-semibold tracking-tight">
                    $400
                  </h1>
                  <span className="text-zinc-600 font-heading">/ month</span>
                </div>

                <p className="text-zinc-700 mt-6 text-sm">
                  Crafted for teams seeking an unparalleled,
                  privacy-friendly testing experience.
                </p>
                <ul className="text-zinc-700 mt-8 space-y-3 text-sm font-display">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> 10 workspaces
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    1000 AI Suggestions
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    2000 AI Steps
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    1000 Tests
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Infinite Flow Steps
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    100 Test Suites
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Exclusive 24/7 Support
                  </li>
                </ul>
                <button
                  onClick={() => handlePriceSubmit("Pro")}
                  className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-800/50 bg-zinc-900/10 text-zinc-950 hover:border-zinc-800/80"
                >
                  Contact Sales
                </button>
              </div>
              {/* card3 */}

              <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl sm:rounded-b-none lg:rounded-br-3xl lg:rounded-tl-none rounded-3xl p-8 ring-1 ring-zinc-800 backdrop-blur-md">
                <h4 className="text-zinc-400 text-3xl font-display font-semibold leading-loose">
                  Custom{" "}
                  <span className="text-sm text-primary-color-light/70 font-normal">
                    (For large enterprises)
                  </span>
                </h4>
                <div className="mt-4 flex items-baseline gap-x-2">
                  <h1 className="text-zinc-400 text-5xl font-semibold tracking-tight">
                    Talk to us
                  </h1>
                  {/* <span className="text-zinc-500 font-heading">Custom</span> */}
                </div>
                <p className="text-zinc-500 mt-6 text-sm">
                  Customized for enterprises seeking a full-fledged
                  testing experience.
                </p>
                <ul className="text-zinc-500 mt-8 space-y-3 text-sm font-display">
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline /> Custom workspaces
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Custom AI Suggestions
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Custom AI Steps
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Custom Flows
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Custom Flow Steps
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Custom Test Suites
                  </li>
                  <li className="flex items-center gap-x-3">
                    <IoCheckmarkDoneOutline />
                    Exclusive 24/7 Support
                  </li>
                </ul>
                <button
                  onClick={() => handlePriceSubmit("Custom")}
                  className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                >
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppTestimonials />
      <AppBannerPage />
    </div>
  );
};

export default PricingPage;
