import React, { useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { joinWaitList } from "../../../../../api/services/apiService";
import { favicon } from "../../assets";
import { layout } from "../../styles/styles";

const AppComingSoon = () => {
  const [email, setEmail] = useState();
  const [isWaitlisted, setIsWaitlisted] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };
  const handleSendButtonClick = () => {
    if (isValidEmail) {
      let payload = {
        email: email,
      };
      joinWaitList(payload)
        .then((res) => {
          if (res) {
            setEmail("");
            setIsWaitlisted(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsValidEmail(false);
        });
    } else {
      setIsValidEmail(false);
    }
  };
  return (
    <section className={`${layout.section} overflow-hidden`} id="appcomingsoon">
      <div className="text-center flex flex-row items-center justify-center -mb-14 md:-mb-20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="40"
          fill="none"
          style={{ animationDelay: "1.5s" }}
          className="animate-top-announcement-glow opacity-1 absolute top-[2px] right-[220px]"
          id="1"
        >
          <circle cx="40" r="40" fill="url(#1-a)" fill-opacity="0.5"></circle>
          <defs>
            <radialGradient
              id="1-a"
              cx="0"
              cy="0"
              r="1"
              gradientTransform="matrix(0 40 -40 0 40 0)"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#47EBEB"></stop>
              <stop offset="1" stop-color="#47EBEB" stop-opacity="0"></stop>
            </radialGradient>
          </defs>
        </svg>
        <h2 className="text-[10px] md:text-xl font-primary font-bold px-2 md:px-6 py-2 mb-4 border border-[#a3aad041] rounded-3xl secondary-heading">
          We're not just stopping here!
        </h2>
      </div>
      <div className="text-center py-16 md:py-28">
        <p
          className="font-primary font-bold  text-3xl md:text-6xl lg:text-7xl md:-mb-3"
          style={{
            background: "linear-gradient(90deg, #F8F8F9, #FAAD1B)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            textShadow: "-2px 3px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          Autoflow Studio is
        </p>
        <p
          className="text-6xl md:text-[150px] lg:text-[200px] tracking-tighter md:tracking-tight font-primary font-bold pb-4"
          style={{
            background: "linear-gradient(90deg, #F8F8F9, #F4AC38)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            textShadow: "-2px 3px 4px rgba(0, 0, 0, 0.5)",
            animation: "colorChange1 5s infinite alternate",
          }}
        >
          Coming Soon
        </p>
      </div>
      <div className="flex flex-row items-center justify-center -mt-10 pb-20">
        <p className="text-xs md:text-lg font-primary font-bold px-2 md:px-6 py-2 secondary-subheading">
          Transforming the Landscape of Test Creation and Management.
        </p>
      </div>

      <div className="text-center p-5 md:p-0 py-32 md:py-28 flex flex-row items-center justify-center relative">
        <div className="rounded-full h-[200px] md:h-60 w-[400px] md:w-[600px] bg-gradient-to-r from-fuchsia-600/10 to-amber-400/50 blur-[200px]" />
        {/* outer box */}
        <div className="absolute h-[320px] md:h-[394px] w-[360px] md:w-[810px]">
          {/* inner box */}
          <div className="px-2 h-[300px] md:h-96 w-[350px] md:w-[800px] rounded-xl shadow-[#faad1b_0px_2px_8px]">
            <div className="flex items-center justify-center py-4 md:py-5">
              <img src={favicon} alt="autoflow logo" className="w-14 md:w-20" />
            </div>
            <div className="py-5 md:py-10">
              <h2 className="text-lg md:text-3xl font-primary font-bold text-white mb-4">
                Be the first to get some in-app
                <br className="flex md:hidden" /> testing action
              </h2>
              <p className="text-gray-300/80 text-center font-secondary font-light mb-6 text-[10px] md:text-base">
                Subscribe to get early access to our application as soon as it
                is released
              </p>
            </div>
            <div className="flex justify-center">
              {isWaitlisted ? (
                <p className="text-gray-300/90 text-center font-secondary mb-6 text-[10px] md:text-base  px-4 py-1 rounded-xl shadow-[#faad1b_0px_0px_5px] ">
                  Congratulations! You're now a part of the AutoFlow Club!
                </p>
              ) : (
                <div className="flex flex-col">
                  <div
                    className={`flex flex-row join items-center justify-center font-primary font-bold bg-white w-[300px] md:w-[450px] p-1 ${
                      !isValidEmail && "border-red-500"
                    }`}
                  >
                    <MdOutlineEmail className="hidden md:flex text-black md:text-xl md:-mr-5 md:ml-2" />
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                      className={`px-5 md:px-10 md:w-[400px] bg-transparent rounded-l outline-none text-black text-sm md:text-base `}
                    />
                    <button
                      className="btn btn-sm md:btn-md bg-primary-color hover:bg-primary-color/90 text-xs md:text-base font-secondary border-none px-3 md:px-6 py-1 md:py-3"
                      onClick={handleSendButtonClick}
                    >
                      Join Waitlist
                    </button>
                  </div>
                  {!isValidEmail && (
                    <p className="text-start text-red-500 text-xs m-2">
                      *Please enter a valid email address.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppComingSoon;
