import React from "react";

const AppStatements = () => {
  return (
    <section>
      <div className="relative mx-auto max-w-5xl px-4 sm:px-6 font-display">
        <div className="py-10">
          <svg
            preserveAspectRatio="xMinYMin"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 46 16"
            className="relative z-10"
          >
            <text
              xmlns="http://www.w3.org/2000/svg"
              x="50%"
              y="50%"
              fill="transparent"
              dominantBaseline="central"
              fontWeight="bold"
              fontFamily="Inter Variable, sans-serif"
              textAnchor="middle"
              textRendering="geometricPrecision"
              strokeWidth=".09"
              paintOrder="stroke"
              className="stroke-primary-color/30 fill-zinc-950/60"
            >
              50K
            </text>
          </svg>

          <div className="absolute inset-0 z-20 flex items-center justify-center">
            <p
              className="max-w-2xl font-semibold tracking-tight text-center text-primary-color/50 text-md md:text-4xl font-display"
              style={{
                transform: "translateZ(0px)",
                opacity: "1",
              }}
            >
              Autoflow runs over <br />
              <span className="text-zinc-50">
              50 thousand test statements per week
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppStatements;
