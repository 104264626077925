import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import AppLandingPageLayout from "./layout/AppLandingPage/AppLandingPageLayout";
import DownloadsPage from "./pages/AppLandingPage/DownloadsPage";
import HomePage from "./pages/AppLandingPage/HomePage";
import PricingPage from "./pages/AppLandingPage/PricingPage";
import PrivacyPolicy from "./components/SdkLandingPageComponents/landingpage/components/PrivacyPolicy";
import ReturnPolicy from "./components/SdkLandingPageComponents/landingpage/components/ReturnPolicy";
import TermsOfService from "./components/SdkLandingPageComponents/landingpage/components/TermsOfService";
import NotFound from "./pages/NotFound";
import SDKLayout from "./layout/SDKLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitForm from "./components/AppLandingPageComponents/tallyForms/Form";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<AppLandingPageLayout />}>
            <Route path="downloads" Component={DownloadsPage} />
            <Route path="pricing" Component={PricingPage} />
            <Route path="contact-us" Component={SubmitForm} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<TermsOfService />} />
            <Route path="return-policy" element={<ReturnPolicy />} />
            <Route index Component={HomePage} />
          </Route>
          <Route path="/sdk/*" 
          element={<SDKLayout />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Router>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={6000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          toastStyle={{
            width: "auto",
            fontFamily: "Inter Variable",
            fontWeight: "bold",
          }}
        />
      </div>
    </>
  );
};

export default App;
