import { client1, client2, client3, client4 } from "../../../../assets";

export const clientsData = [
  {
    id: 1,
    name: "Cliente 1",
    icon: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1711459222/maximWhite_v5o3ek.png",
  },
  {
    id: 2,
    name: "Cliente 2",
    icon: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1711461949/Enitiate-removebg_tp6jow.png",
  },
  {
    id: 3,
    name: "Cliente 3",
    icon: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1711459222/SkilleWhite_iaa6oe.png",
  },
  {
    id: 4,
    name: "Cliente 4",
    icon: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1711459222/flamWhite_rr8rlg.png",
  },
];
