import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmailValid } from "../../utils/utlilityFunctions/checkUserFields";
import { BASE_URL } from "./constant";
import { sdkHome, sdkLogin } from "../../routes/routes";
import { logo_square_without_name } from "../../assets";

const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const handleForgetPassword = async (data) => {
    setLoading(true);
    const emailValidity = isEmailValid(data.email);
    if (!emailValidity.isValid) {
      setLoading(false);
      toast.error(emailValidity.error);
      const invalidEmailError = new Error(emailValidity.error);
      invalidEmailError.stack = undefined;
      throw invalidEmailError;
    }
    try {
      const response = await axios.post(`${BASE_URL}/forget-password`, {
        email: data.email,
      });
      if (response && response.status === 200) {
        setLoading(false);
        toast.success(
          "The email containing the reset link for your password has been successfully dispatched."
        );
      } else if (response && response.status === 400) {
        setLoading(false);
        toast.error(
          "Oops. Something went wrong. Kindly contact us at contact@autoflowapp.com"
        );
      } else {
        setLoading(false);
        toast.error(
          "Oops. Something went wrong. Kindly contact us at contact@autoflowapp.com"
        );
      }
      return response;
    } catch (error) {
      setLoading(false);
      toast.error(
        "Oops. Something went wrong. Kindly contact us at contact@autoflowapp.com; Error: ",
        error
      );
      throw error;
    }
  };

  return (
    <div className="relative h-screen w-screen flex items-center justify-center bg-primary-color-dark overflow-hidden">
      <div
        className="flex items-center justify-center w-[200px] h-[200px] sm:w-[500px] sm:h-[500px] md:w-[650px] md:h-[650px] rounded-full"
        style={{
          background:
            "radial-gradient(circle at 50% 50%, #997529 0%, #985F2A 0%, #A1792D 0%, #985F2A 26%, #966125 41%, #825520 54%, #684323 71%, #2D2A2A 82%, #2D2A2A 100%)",
          boxShadow: "4px 6px 95px 29px #8f681b inset",
          animation: "colorChange1 5s infinite alternate",
        }}
      />
      <div className="absolute flex flex-row justify-end h-full w-screen">
        <div className="h-full w-full sm:w-1/2 backdrop-blur-[45px]">
          <div className="flex flex-row items-center justify-center h-full w-full overflow-y-scroll scrollable-content">
            <div className="flex flex-col items-center justify-center">
              <div className="flex justify-center flex-grow">
                <Link to={sdkHome}>
                  <img
                    src={logo_square_without_name}
                    alt="logo"
                    className="object-cover h-20 w-20"
                  />
                </Link>
              </div>
              <h1 className="text-white  text-3xl md:text-4xl mt-5 md:mt-8 font-primary font-bold ">
                Forget Password
              </h1>

              <form action="" onSubmit={handleSubmit(handleForgetPassword)}>
                <div className="flex flex-col justify-center mt-10 mb-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label -mb-1">
                      <span className="label-text font-secondary text-white/70">
                        Email
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      {...register("email", {
                        required: "*Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[450px] ${
                        errors?.email && "border-red-500 focus:border-red-500"
                      }`}
                    />
                    <div className="label">
                      <span className="label-text-alt">
                        {errors.email && (
                          <p className="text-red-500">{errors.email.message}</p>
                        )}
                      </span>
                    </div>
                  </label>
                </div>
                <div className="mt-6">
                  <button
                    className={`btn btn-sm md:btn-md bg-primary-color border-none outline-none hover:bg-primary-color/85 w-[350px] md:w-[450px] font-secondary text-base ${
                      loading && "btn-disabled"
                    }`}
                  >
                    {loading ? (
                      <span className="loading loading-bars loading-sm md:loading-lg text-warning"></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>

              <div className="text-center mt-8 md:mt-10">
                <p className="flex flex-row items-center justify-center gap-x-2 text-white/55 font-secondary ">
                  <span className="flex flex-row items-center justify-center gap-x-2">
                    <FaArrowLeft /> Back to
                  </span>{" "}
                  <Link
                    to={sdkLogin}
                    className="text-blue-300 font-secondary font-bold hover:text-blue-200"
                  >
                    log in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
