import React, { memo } from "react";
import { Handle, Position } from "reactflow";

function CustomNode({ data }) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          width: "0.5rem",
          height: "2rem",
          background: "#FAAD1B",
          borderRadius: "8px 0px 0px 8px",
          border: "none",
        }}
      />
      <div
        className={`card w-96 bg-primary-color-dark shadow-xl div-with-shadow`}
      >
        <div className="card-body p-0 px-4">
          <p className="card-title text-secondary-color-light/80 py-2 text-[15px] leading-6 font-inter">
            {data.label}
          </p>
        </div>
        <div className="flex flex-col gap-2 justify-between bg-primary-color-dark px-4 py-3 rounded-b-lg font-robotomono">
          <div>
            <p className="text-sm text-[#60a5fa]">
              Steps: <span className="text-[#e879f9]">{data.steps}</span>
            </p>
          </div>
          <div>
            <p className="text-sm text-[#60a5fa]">
              Last Run: <span className="text-[#e879f9]">{data.last_run}</span>
            </p>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        style={{
          cursor: data.status === "draft" ? "not-allowed" : "crosshair",
          width: "0.5rem",
          height: "2rem",
          background: "#FAAD1B",
          borderRadius: "0px 8px 8px 0px",
          border: "none",
          padding: "0px 0px 0px 4px",
          zIndex: "-1",
        }}
      />
    </>
  );
}

export default memo(CustomNode);
