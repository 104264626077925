import axios from "axios";
import React, { useState } from "react";
import { MdError } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "./constant";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleChangePassword = async (event) => {
    event.preventDefault();

    axios
      .put(`${BASE_URL}/change-password`, {
        newPassword: newPassword,
        oldPassword: oldPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          navigate("/login", {
            message:
              "Your password has been changed successfully. You can login again with your new password.",
          });
        } else if (response.status === 401) {
          window.alert(
            "You are not authorized to change the password. Please try again."
          );
        } else if (response.status === 400) {
          window.alert("Invalid request. Please try again.");
        } else if (response.status === 409) {
          window.alert(
            "Old password and new password cannot be same. Please try again."
          );
        } else {
          window.alert("Something went wrong. Please try again.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col  h-screen font-secondary">
      <div className="flex items-center relative justify-center h-screen bg-secondary-color flex-col">
        <div className="p-8 rounded-xl shadow-md w-full max-w-xl glass bg-secondary-color justify-center">
          <h1 className="text-2xl font-semibold mb-4 text-center pt-10 text-primary-color font-primary">
            Change Password
          </h1>
          <form onSubmit={handleChangePassword}>
            {error && (
              <p className=" flex justify-center gap-1 border-red-500 capitalize mt-2 text-lg rounded-lg border items-center text-red-500">
                {error} <MdError />{" "}
              </p>
            )}
            <div className="flex justify-center mt-5">
              <input
                type="password"
                placeholder="Old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="input input-bordered input-md w-full max-w-xs focus:outline-none focus:outline-primary-color"
                required
              />
            </div>
            <div className="flex justify-center mt-1">
              <input
                type="password"
                placeholder="New password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="input input-bordered input-md w-full max-w-xs focus:outline-none focus:outline-primary-color"
                required
              />
            </div>
            <div className="flex justify-center mt-2">
              <button
                type="submit"
                className="btn btn-wide glass bg-primary-color text-secondary-color font-primary font-bold"
              >
                Submit
              </button>
            </div>
          </form>
          <div className="flex justify-center mt-2 text-primary-color">
            <Link to="/login">Back to Login</Link>
          </div>
        </div>
      </div>
      {/* Copyright section */}
      <div className="text-primary-color bg-secondary-color text-center">
        <p>
          Copyright © 2024 Reveedo Technologies Pvt. Ltd. All rights reserved.
        </p>
        <p>
          <Link to="/privacy">
            {" "}
            <span>Terms of Service</span> | <span>Privacy</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ChangePassword;
