import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { appSubmitForm } from "../../../../routes/routes";
import * as styles from "./appIntroContentStyles";
import axios from "axios";

const AppIntroContent = ({ bannerPage }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCTASubmit = (submitData) => {
    console.log(submitData, "data");
    const data = {
      name: submitData.name,
      email: submitData.email,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/auth/waitlist`,
        data,
        config
      )
      .then((response) => {
        console.log(response.data);
        const data = {
          title: "Get Early Access",
          description: "Subscribe to get early access to AutoFlow Studio",
          src: "https://tally.so/embed/3lydqB?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1",
        };
        navigate(appSubmitForm, { state: { data: data } });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className={styles.container}>
      <div className={styles.introWrapper}>
        <div className={styles.introBox}>
          <span className={styles.introbg}></span>
          <span className={styles.introIcon}>✦</span>
          <span className="font-display font-normal">
            Introducing Autoflow Desktop Application
          </span>
        </div>
      </div>
      <h1
        className={`${styles.mainHeading} ${
          bannerPage === true ? "text-3xl md:text-5xl" : "text-5xl md:text-7xl"
        } font-heading`}
      >
        Automate Tests 10X Faster
      </h1>
     
      <p
        className={`${styles.subHeading} ${
          bannerPage === true ? "mb-8" : "mb-14"
        } font-display`}
      >
        Effortlessly Conduct Cross Browser Testing for Complex User Journeys
        Without Code
      </p>
      {bannerPage !== true && (
        <div
          className={`hidden md:block md:absolute top-[180px] md:top-60 -left-14`}
        >
          <p className="text-secondary-color-light/30 text-xs font-display">
            Get started with <br /> 15 days free trial.
          </p>
        </div>
      )}

      {bannerPage !== true && (
        <svg
          className={`hidden md:absolute top-[200px] md:top-64 left-6 h-[60px] mt-4 fill-primary-color/50 opacity-50 md:block sm:-left-7 sm:mt-5`}
          viewBox="0 0 61 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.54 57.568c.13-.38-.9-.23-1.81-.28-.27-.02-.58-.13-.75-.14-.59-.03-1.24.05-1.94.01-1.48-.13-3-.34-4.55-.49-1.54-.23-3.1-.54-4.67-.81-1.54-.41-3.11-.73-4.62-1.2-1.49-.52-3.02-.91-4.42-1.55-1.13-.49-2.35-.94-3.39-1.54-1.08-.6-2.11-1.17-3.08-1.82-.35-.21-.72-.3-1.08-.5-.33-.19-.53-.45-.84-.64-.75-.44-1.41-.87-2.13-1.36-.4-.29-.79-.58-1.19-.87l-1.13-.97c-.75-.66-1.53-1.28-2.24-1.98-.72-.69-1.53-1.27-2.22-1.98-1.82-1.86-3.49-3.8-5.04-5.88-1.08-1.37-2.11-2.74-3.08-4.2-.83-1.32-1.62-2.75-2.37-4.15-1.64-3-3.21-6.09-4.43-9.29-.23-.63-.37-1.24-.6-1.86-.88-2.53-1.9-5.19-2.59-7.95-.33-1.3-.65-2.62-.94-3.92-.26-1.17-.42-2.64-.93-3.47-.38.13-.28.67-.26 1.44.03.82.23 1.36.5 2.52.6 2.47 1.15 5.14 2.4 8.89 2.42 7.19 6.01 15 11.49 22.29.35.47.71.96 1.09 1.47.41.48.83.98 1.26 1.49 1.66 1.95 3.62 3.87 5.68 5.72 1.06.88 2.13 1.78 3.23 2.61 1.14.78 2.21 1.64 3.36 2.32 1.02.6 2 1.31 3.05 1.86 1.05.55 2.08 1.15 3.14 1.63.67.28 1.38.58 2.08.88.7.3 1.41.58 2.11.79 1.39.44 2.61.92 3.47 1.18.98.22 1.95.44 2.91.66.95.25 1.92.33 2.86.5.95.13 1.87.35 2.8.42.92.09 1.84.19 2.73.28 1.51.2 2.64.24 4.21.19.61-.03 1.78-.02 1.94-.31l-.01.01Z"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.4 53.438c-.32.21-.1.19-.07.28.01.03-.05.11-.05.13.03.06.15.06.18.12.29.57.75 1.12 1.15 1.63.08.1.15.22.21.34.06.12.03.29.1.4.02.04.15 0 .18.03s-.05.12-.03.15c.06.07.17.09.22.17.11.18.15.42.27.59.06.09.19.13.26.21.12.15.21.33.31.5l.06-.64c-.06.05-.12.11-.18.16-.13.13-.19.33-.31.47-.11.12-.29.19-.41.31-.26.26-.43.61-.7.84-.05.05-.16.03-.22.07-.22.18-.3.52-.53.73-.1.1-.19.21-.28.33-.07.1-.25.15-.03.5.29.28.31.22.44.24.14.02.11-.1.17-.21.14-.24.4-.38.66-.7.51-.62 1.1-1.29 1.79-1.96.06-.06.13-.11.21-.17l.2-.18-.16-.27s-.04-.07-.07-.11c-.35-.41-.72-.9-1.02-1.38-.13-.21-.21-.46-.34-.67-.16-.26-.45-.5-.46-.72-.33-.28-.63-.57-.83-.89-.09-.14-.09-.27-.29-.34-.08-.03-.17-.11-.43.05v-.01Z"
          ></path>
        </svg>
      )}
      <div className={styles.buttonContainer}>
        <div className="relative overflow-hidden rounded-2xl p-[1px]">
          <span className={styles.spinner}></span>
          <form
            action=""
            onSubmit={handleSubmit(handleCTASubmit)}
            className="flex flex-col sm:flex-row items-center justify-center gap-y-2 sm:space-x-2 border border-secondary-color-light/5 rounded-2xl w-full p-2  bg-[linear-gradient(110deg,_theme(colors.zinc.950),45%,_theme(colors.zinc.900),55%,_theme(colors.zinc.950))] bg-[length:200%_100%] backdrop-blur-md animate-background-shine"
          >
            <div className="flex flex-col sm:flex-row gap-y-2 sm:gap-y-0  backdrop-blur-md">
              <div
                className={`relative border px-3 py-1 sm:py-3 md:py-5 bg-black/40 w-full rounded-md md:rounded-xl md:rounded-r-none ${
                  errors?.name
                    ? "border-red-500/50"
                    : "border-secondary-color-light/15"
                }`}
              >
                <input
                  type="text"
                  placeholder="Name"
                  {...register("name", {
                    required: "*name is required",
                  })}
                  className="bg-black/5 text-secondary-color-light/50 text-xs sm:text-sm md:text-base font-display w-64 outline-none"
                />
                {errors?.name && (
                  <p className="absolute -bottom-3 right-0 text-red-500 text-[10px]">
                    {errors.name?.message}
                  </p>
                )}
              </div>
              <div
                className={`relative border px-3 py-1 sm:py-3 md:py-5 bg-black/40 w-full rounded-md md:rounded-xl md:rounded-l-none ${
                  errors?.email
                    ? "border-red-500/50"
                    : "border-secondary-color-light/15"
                }`}
              >
                <input
                  type="text"
                  {...register("email", {
                    required: "*email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                      message: "*Invalid email address",
                    },
                  })}
                  placeholder="Company Email"
                  className="w-64 bg-black/5 text-secondary-color-light/50 text-xs sm:text-sm md:text-base font-display outline-none"
                />
                {errors?.email && (
                  <p className="absolute -bottom-3 right-0 text-red-500 text-[10px]">
                    {errors.email?.message}
                  </p>
                )}
              </div>
            </div>
            <button className="border border-secondary-color-light/10 px-10 md:px-14 py-1 sm:py-[11px] md:py-4 bg-primary-color rounded-md md:rounded-xl w-full text-sm sm:text-base md:text-lg text-black font-display backdrop-blur-md">
              Get Started
            </button>
          </form>{" "}
        </div>
      </div>
    </div>
  );
};

export default AppIntroContent;
