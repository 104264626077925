import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { toast } from "react-toastify";
import { postResetPassword } from "../../api/services/apiService";
import { sdkHome, sdkLogin } from "../../routes/routes";
import { logo_square_without_name } from "../../assets";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [shownPassword, setShownPassword] = useState(false);
  const [shownConfirmPassword, setShownConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("user_id");
  const token = params.get("token");

  const validatePassword = (value) => {
    // Add your custom password validation logic here
    if (value.length < 6) {
      return "Password must be at least 6 characters";
    }
    return true;
  };

  const validateConfirmPassword = (value) => {
    // Validate if confirm password matches new password
    if (value !== watch("password")) {
      return "Passwords do not match";
    }
    return true;
  };

  const handleResetPassword = async (data) => {
    setLoading(true);

    if (data.password !== data.confirmPassword) {
      setLoading(false);
      toast.error("Passwords do not match");
      return;
    }
    let payload = {
      user_id: userId,
      password: data.confirmPassword,
      token: token,
    };

    postResetPassword(payload)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          navigate(sdkLogin, {
            state: {
              message:
                "Your password has been reset successfully. You can login again with your new password.",
            },
          });
        } else if (response.status === 401) {
          toast.error(
            "You are not authorized to reset the password. Please try again."
          );
        } else if (response.status === 400) {
          toast.error("Invalid request. Please try again.");
        } else if (response.status === 409) {
          toast.error(
            "Old password and new password cannot be the same. Please try again."
          );
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      })
      .catch((error) => {

        setLoading(false);
        if (error?.response) {
          toast.error(error.response.data.message || "Something went wrong.");
        } else if (error.request) {
          toast.error("No response received from the server.");
        } else {
          toast.error("An error occurred while processing your request.");
        }
      });
  };
  return (
    <div className="relative h-screen w-screen flex items-center justify-center bg-secondary-color overflow-hidden">
      <div
        className="flex items-center justify-center w-[200px] h-[200px] sm:w-[500px] sm:h-[500px] md:w-[650px] md:h-[650px] rounded-full"
        style={{
          background:
            "radial-gradient(circle at 50% 50%, #997529 0%, #985F2A 0%, #A1792D 0%, #985F2A 26%, #966125 41%, #825520 54%, #684323 71%, #2D2A2A 82%, #2D2A2A 100%)",
          boxShadow: "4px 6px 95px 29px #8f681b inset",
          animation: "colorChange1 5s infinite alternate",
        }}
      />
      <div className="absolute flex flex-row justify-end h-full w-screen">
        <div className="h-full w-full sm:w-1/2 backdrop-blur-[45px]">
          <div className="relative flex flex-row items-center justify-center h-full w-full overflow-y-scroll scrollable-content">
            <div className="flex flex-col items-center justify-center">
              <div className="flex justify-center flex-grow">
                <Link to={sdkHome}>
                  <img
                    src={logo_square_without_name}
                    alt="logo"
                    className="object-cover h-20 w-20"
                  />
                </Link>
              </div>
              <h1 className="text-white  text-3xl md:text-4xl mt-5 md:mt-8 font-primary font-bold ">
                Reset Password
              </h1>

              <form action="" onSubmit={handleSubmit(handleResetPassword)}>
                <div className="flex flex-col justify-center -mb-2 mt-10">
                  <label className="form-control w-full max-w-xs">
                    <div className="label -mb-1">
                      <span className="label-text font-secondary text-white/70">
                        New Password
                      </span>
                    </div>

                    <div
                      className={`join w-[350px] md:w-[515px] border border-border-white/70 flex items-center`}
                    >
                      <input
                        type={shownPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        {...register("password", {
                          validate: validatePassword,
                        })}
                        className={`input input-sm md:input-md bg-transparent border-none outline-none focus:border-white/70 text-white font-secondary w-full ${
                          errors?.password &&
                          "border-red-500 focus:border-red-500"
                        }`}
                      />
                      <span
                        className="w-1/12 text-white text-center text-xl cursor-pointer"
                        onClick={() => setShownPassword(!shownPassword)}
                      >
                        {shownPassword ? <IoEye /> : <IoEyeOff />}
                      </span>
                    </div>

                    <div className="label">
                      <span className="label-text-alt">
                        {errors?.password && (
                          <p className="text-red-500">
                            {errors.password.message}
                          </p>
                        )}
                      </span>
                    </div>
                  </label>
                </div>
                <div className="flex flex-col justify-center -mb-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label -mb-1">
                      <span className="label-text font-secondary text-white/70">
                        Confirm Password
                      </span>
                    </div>

                    <div
                      className={`join  w-[350px] md:w-[515px] border border-border-white/70 flex items-center`}
                    >
                      <input
                        type={shownConfirmPassword ? "text" : "password"}
                        placeholder="confirm password"
                        {...register("confirmPassword", {
                          validate: validateConfirmPassword,
                        })}
                        className={`input input-sm md:input-md bg-transparent border-none outline-none focus:border-white/70 text-white font-secondary w-full ${
                          errors?.password &&
                          "border-red-500 focus:border-red-500"
                        }`}
                      />
                      <span
                        className="w-1/12 text-white text-center text-xl cursor-pointer"
                        onClick={() =>
                          setShownConfirmPassword(!shownConfirmPassword)
                        }
                      >
                        {shownConfirmPassword ? <IoEye /> : <IoEyeOff />}
                      </span>
                    </div>

                    <div className="label">
                      <span className="label-text-alt">
                        {errors?.confirmPassword && (
                          <p className="text-red-500">
                            {errors.confirmPassword?.message}
                          </p>
                        )}
                      </span>
                    </div>
                  </label>
                </div>

                <div className="mt-6">
                  <button className="btn btn-sm md:btn-md bg-primary-color border-none outline-none hover:bg-primary-color/85 w-[350px] md:w-[515px] font-secondary text-base">
                    {loading ? (
                      <span className="loading loading-bars loading-sm md:loading-lg text-warning"></span>
                    ) : (
                      "Reset password"
                    )}
                  </button>
                </div>
              </form>

              <div className="text-center mt-8 md:mt-10">
                <p className="flex flex-row items-center justify-center gap-x-2 text-white/55 font-secondary ">
                  <span className="flex flex-row items-center justify-center gap-x-2">
                    <FaArrowLeft /> Back to
                  </span>{" "}
                  <Link
                    to={sdkLogin}
                    className="text-blue-300 font-secondary font-bold hover:text-blue-200"
                  >
                    log in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
