import React from "react";

const AppSectionHeader = ({ title, mainTitle, description }) => {
  return (
    <div className="flex flex-col items-center max-w-2xl pb-12 mx-auto text-center md:pb-20 font-display">
      <span className="inline-flex mb-6 text-transparent bg-clip-text max-w-max bg-gradient-to-br from-zinc-50 to-zinc-600">
        {title}
      </span>
      <h2
        className="block pb-4 text-3xl sm:text-4xl md:text-5xl text-transparent bg-clip-text max-w-max bg-gradient-to-r from-white via-white/80 to-white/30"
        style={{ fontFamily: "Plus Jakarta Sans Variable, sans-serif" }}
      >
        {mainTitle}
      </h2>
      <p className="text-secondary-color-light/50 ">{description}</p>
    </div>
  );
};

export default AppSectionHeader;
