export const containerStyles = {
    container: "relative mx-auto flex max-w-5xl flex-col items-center p-10 before:absolute before:inset-x-0 before:top-0 before:h-px before:bg-gradient-to-r before:from-transparent before:via-zinc-500/20",
    heading: "block text-3xl max-w-max bg-gradient-to-r from-white via-white/80 to-white/30 bg-clip-text pb-4 text-center text-transparent",
    paragraph: "mt-2 max-w-md text-center font-display font-normal text-secondary-color-light/40",
    form: "mt-8 flex w-full max-w-sm flex-1 flex-col gap-4 sm:flex-row relative",
    input: "w-full rounded-xl border border-zinc-500/50 bg-gradient-to-b from-zinc-950 via-zinc-950 to-zinc-900 px-4 py-2 backdrop-blur-md transition placeholder:text-zinc-400/50 hover:border-secondary-color-light/40 text-secondary-color-light/50 outline-none font-display font-medium",
    submitButton: "relative inline-flex cursor-pointer items-center justify-center rounded-xl border border-zinc-500/50 bg-transparent bg-gradient-to-b from-zinc-950 via-zinc-950 to-zinc-900 px-3 py-2 text-sm transition hover:border-secondary-color-light/40 text-secondary-color-light font-display",
  };
  
  export const footerGridStyles = {
    grid: "grid gap-8 py-4 sm:grid-cols-12 md:py-8",
  };
  
  export const logoStyles = {
    container: "mb-4 sm:mb-0",
    image: "h-6 w-6",
    text: "text-xs text-secondary-color-light/50 font-display font-normal text-center sm:text-left",
  };
  
  export const systemStatusStyles = {
    status: "flex items-center rounded text-xs text-secondary-color-light/40 transition hover:text-secondary-color-light/50",
  };
  
  export const socialLinkStyles = {
    linkList: "flex items-center overflow-hidden rounded-full border border-zinc-800",
    // linkItem: "border-r border-zinc-800",
    linkButton: "flex items-center justify-center rounded-full text-zinc-400/80 transition hover:text-zinc-100",
    icon: "h-8 w-8 p-2",
  };
  