import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { updateInviteStatus } from '../../api/services/apiService';

function AccountCreated() {
  const locationState = useLocation()?.state;
  const organisation = locationState?.organisation;
  const email = locationState?.email;
  const inviteId = locationState?.inviteId;

  // Now you can use the data in your component
  useEffect(() => {
    if (inviteId) {
    const payload = {
        inviteId: inviteId,
        status: "accepted",
        };
      updateInviteStatus(payload);
    }
  }, []);



  return (
    <div className="relative h-screen w-screen flex items-center justify-center bg-secondary-color overflow-hidden">
    <div>
      <h1 className="text-black text-base md:text-lg font-display mt-4" >Your account has been created using email {email} !</h1>
      <p className="text-black text-base md:text-lg font-display mt-4" >You are now part of {organisation} organisation. Please go to the app and sign in with your credentials.</p>
    </div>
    </div>
  );
}

export default AccountCreated;