import axios from "axios";

const configUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const authUrl = process.env.REACT_APP_AUTH_BASE_URL;
export const getAxiosInstance = () => {
  axios?.interceptors.request.use((req) => {
    return req;
  });

  // Response interceptor for API calls
  axios?.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      if (error.response?.status === 400) {
        return error?.response?.data;
      }
      if (!error.response?.config) {
        return error;
      }
    }
  );
};


export const updateInviteStatus = async (payload) => {
  const path = `/api/v1/auth/update-invite-status`;
  try {
    const response = await axios.request({
      url: `${authUrl}${path}`,
      method: "post",
      data: payload,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const profile1 = async () => {
  console.log('profile function called');
  const path = `/api/v1/accounts/user-info`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const profile2 = async () => {
  console.log('profile function called');
  const path = `/api/v1/accounts/user-info`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const profile3 = async () => {
  console.log('profile function called');
  const path = `/api/v1/accounts/user-info`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const updateProfile = async (payload) => {
  const path = `/api/v1/accounts/name-organisation`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "put",
      data: payload,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getApiKeys = async () => {
  const path = `/api/v1/automind/api-keys`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const getUsageData = async () => {
  const path = `/api/v1/automind/usage-data`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const generateApiKeys = async (payload) => {
  const path = `/api/v1/automind/api-key`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "post",
      data: payload,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const joinWaitList = async (payload) => {
  const path = `/api/v1/accounts/waitlist`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "post",
      data: payload,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const postTransaction = async (payload) => {
  const path = `/api/v1/accounts/transaction`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "post",
      data: payload,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getBillingDetails = async () => {
  const path = `/api/v1/accounts/billing-details`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const verifyEmail = async () => {
  const path = `/api/v1/auth/send-verify-email`;
  try {
    const response = await axios.request({
      url: `${authUrl}${path}`,
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const postResendVerifyEmail = async (payload) => {
  const path = `/api/v1/auth/send-verify-email`;
  try {
    const response = await axios.request({
      url: `${authUrl}${path}`,
      method: "post",
      data: payload,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const postResetPassword = async (payload) => {
  const path = `/api/v1/auth/reset-password`;
  try {
    const response = await axios.request({
      url: `${authUrl}${path}`,
      method: "post",
      data: payload,
      // withCredentials: true,
    });
    return response;
  } catch (error) {
    if (error.response) {
      console.error("Error:", error.response.data);
      throw error.response.data;
    } else {
      console.error("Error:", error);
      throw error;
    }
  }
};


export const postRefCheck = async (payload) => {
  const path = `/api/v1/accounts/event`;
  try {
    const response = await axios.request({
      url: `${configUrl}${path}`,
      method: "post",
      data: payload,
      withCredentials: true,
    });
    return response;
  } catch (error) {
    if (error.response) {
      console.error("Error:", error.response.data);
      throw error.response.data;
    } else {
      console.error("Error:", error);
      throw error;
    }
  }
};