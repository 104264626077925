export const appHome = "/";
export const appDownloads = "/downloads";
export const appPricing = "/pricing";
export const appSubmitForm = "/contact-us";
export const sdkHome = "/sdk";
export const sdkLogin = "/sdk/login";
export const sdkSignUp = "/sdk/signup";
export const sdkForgotPwd = "/sdk/forget-password";
export const sdkResetPwd = "/sdk/reset-password";
export const sdkChangePwd = "/sdk/change-password";
export const sdkVerifyEmail = "/sdk/verify-email";
export const sdkCreateAccount = "/sdk/create-account";
export const sdkAccount = "/sdk/account";
export const sdkBilling = "/sdk/account/billing";
export const sdkApiKeys = "/sdk/account/api";
export const sdkProfile = "/sdk/account/profile";
export const sdkPrivacy = "/sdk/privacy";
export const sdkTerms = "/sdk/terms";
export const sdkReturnPolicy = "/sdk/returnpolicy";
export const sdkInfoPage = "/sdk/infoPage";
