import React from "react";

const FeaturesCard = ({ title, description, img, alt }) => {
  return (
    <div className="card border border-gray-800 py-8 w-[360px] md:w-96 h-[280px] shadow-lg hover:shadow-[#7b5f2c] cursor-pointer">
     <div className="px-8 py-4"  >
        <img src={img} alt={alt} className="h-14 w-14" />
      </div>
      <div className="card-body -mt-4">
        <h2 className="card-title cursor-pointer text-primary-color/90  hover:text-primary-color text-lg sm:text-xl font-primary font-bold">
          {title}
        </h2>
        <p className="font-secondary font-light pt-1 text-sm sm:text-base text-white/55">
          {description}
        </p>
      </div>
    </div>
  );
};

export default FeaturesCard;
