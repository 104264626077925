import React, { useEffect, useState } from "react";
import { BsArrowUpSquareFill } from "react-icons/bs";
import { Link, animateScroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/SdkLandingPageComponents/landingpage/components/nav/NavBar";
import Hero from "../../components/SdkLandingPageComponents/landingpage/components/hero/Hero";
import Stats from "../../components/SdkLandingPageComponents/landingpage/components/Stat/Stats";
import WhyUse from "../../components/SdkLandingPageComponents/landingpage/components/whyUse";
import Features from "../../components/SdkLandingPageComponents/landingpage/components/features";
import Pricing from "../../components/SdkLandingPageComponents/landingpage/components/pricing";
import AppComingSoon from "../../components/SdkLandingPageComponents/landingpage/components/appComingSoon";
import Footer from "../../components/SdkLandingPageComponents/landingpage/components/footer/Footer";
import { postRefCheck } from "../../api/services/apiService";

const SdkLandingPage = ({ sessionExists }) => {
  const location = useLocation();
  const [scrollTop, setScrollTop] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 500) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    document.title = "Autoflow SDK | Free SDK for modern QAs";
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get("ref");
    let payload = {
      ref: refParam || "default",
    };
    postRefCheck(payload)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  }, [location]);

  return (
    <div className="bg-primary-color-dark">
      <div id="top">
        <NavBar sessionExists={sessionExists} />
        <Hero />
        <Stats />
        <WhyUse />
        <Features />
        <Pricing sessionExists={sessionExists} />
        <AppComingSoon />
        <Footer />
      </div>

      {scrollTop && (
        <Link
          smooth={true}
          duration={1000}
          className="fixed bottom-16 right-5 sm:bottom-5 sm:right-40"
        >
          <button
            onClick={scrollToTop}
            className="btn btn-sm btn-ghost px-1 drop-shadow-2xl opacity-65"
          >
            <BsArrowUpSquareFill className="text-2xl sm:text-3xl text-warning shadow-inner" />
          </button>
        </Link>
      )}
    </div>
  );
};

export default SdkLandingPage;
