import React, { useCallback } from "react";
import { CiGlobe } from "react-icons/ci";
import {
  FaChrome,
  FaFirefox,
  FaInternetExplorer,
  FaOpera,
  FaSafari,
} from "react-icons/fa";
import { MdKeyboardCommandKey } from "react-icons/md";
import { SiHotwire } from "react-icons/si";
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/base.css";
import { globe } from "../../../../assets";
import { AI_PoweredFeatures } from "../../../../utils/Data";
import SparkleBackground from "../../../Animations/SparkleBackground";
import AppSectionHeader from "../../reUsableComponents/AppSectionHeader";
import AppCard1 from "../../reUsableComponents/cards/AppCard1";
import AppCard3 from "../../reUsableComponents/cards/AppCard3";
import * as styles from "./AppSetupTestStyles";
import CustomNode from "./CustomNode";

const nodeTypes = {
  custom: CustomNode,
};

const initNodes = [
  {
    id: "1",
    type: "custom",
    data: { label: "Login Flow", steps: "3", last_run: "12 seconds ago" },
    position: { x: -165, y: -14 },
    sourcePosition: "right",
  },
  {
    id: "2",
    type: "custom",
    data: { label: "Wish List Flow", steps: "8", last_run: "2 minutes ago" },
    position: { x: 327, y: -169 },
  },
  {
    id: "3",
    type: "custom",
    data: { label: "Place Order Flow", steps: "7", last_run: "2 minutes ago" },
    sourcePosition: "right",
    position: { x: 813, y: -11 },
  },
  {
    id: "4",
    type: "custom",
    data: { label: "Edit Profile Flow", steps: "4", last_run: "5 seconds ago" },
    position: { x: 330, y: 145 },
  },
];

const initEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: true,
    style: { stroke: "#B29F8A", strokeWidth: "3" },
  },
  {
    id: "e1-3",
    source: "1",
    target: "4",
    animated: true,
    style: { stroke: "#B29F8A", strokeWidth: "3" },
  },
  {
    id: "e1-4",
    source: "2",
    target: "3",
    animated: true,
    style: { stroke: "#B29F8A", strokeWidth: "3" },
  },
];

const AppSetupTest = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const HandleNodeChange = (node) => {
    console.log(node);
    onNodesChange(node);
  };

  return (
    <section className="relative py-20">
      <div className="max-w-5xl px-4 mx-auto sm:px-6">
        <div className="py-12 md:py-16">
          <SparkleBackground>
            <AppSectionHeader
              title="AI Powered"
              mainTitle="Setup Tests in Under 2 Minutes"
              description=" Designed for everyone, by developers."
            />
          </SparkleBackground>

          <div className="relative pb-12 md:pb-16">
            <div className="grid gap-6 md:grid-cols-12 group">
              <div className="grid md:col-span-12">
                <div className={styles.cardContainer}>
                  <AppCard1
                    icon={
                      <MdKeyboardCommandKey className="text-3xl text-white" />
                    }
                    title={"Drag and Drop Interface"}
                    description={
                      "Set up your test suite quickly with a drag drop interface."
                    }
                    descWidth="w-full"
                    content={
                      <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={HandleNodeChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        className="bg-transparent"
                        proOptions={{hideAttribution: true}}
                        fitView
                      >
                        <Background
                          variant="dots"
                          gap={24}
                          size={1}
                          style={{ opacity: 0.5 }}
                        />
                      </ReactFlow>
                    }
                    contentHeight={"h-[450px]"}
                  />
                </div>
              </div>
              <div className="grid md:col-span-6">
                <div className={styles.cardContainer}>
                  <AppCard1
                    icon={<SiHotwire className="text-2xl text-white" />}
                    title={"Multi-browser support"}
                    descWidth="w-3/4"
                    description={
                      "In-built support for Chrome, Firefox, Safari and more."
                    }
                    content={
                      <>
                        <div className="m-4 rounded-full border border-secondary-color-light/10">
                          <div className="m-4 rounded-full border border-secondary-color-light/10">
                            <div className="m-4 rounded-full border border-secondary-color-light/10">
                              <div className="m-4 rounded-full border border-secondary-color-light/10">
                                <div className="m-4 rounded-full border border-secondary-color-light/10">
                                  <div className="m-4 rounded-full border border-secondary-color-light/10">
                                    <div className="m-4 rounded-full border border-secondary-color-light/10">
                                      <div className="m-4 rounded-full border border-secondary-color-light/10">
                                        <div className="m-4 rounded-full border border-secondary-color-light/10">
                                          <div className="m-4 rounded-full border border-secondary-color-light/10">
                                            <div className="relative m-4 h-40 w-40 rounded-full border border-secondary-color-light/10">
                                              <div className="circular circle animate-[spin_100s_linear_infinite_reverse]">
                                                <span className="relative circle rounded-full border border-primary-color/30 p-4 backdrop-blur-md">
                                                  <FaSafari
                                                    className="animate-[spin_100s_linear_infinite] text-secondary-color-light h-6 w-6"
                                                    data-v-1cd58794=""
                                                  />
                                                </span>
                                                <span className="relative circle rounded-full border border-primary-color/30 p-4 backdrop-blur-md">
                                                  <FaChrome
                                                    className="animate-[spin_100s_linear_infinite] text-secondary-color-light h-6 w-6"
                                                    data-v-1cd58794=""
                                                  />
                                                </span>
                                                <span className="relative circle rounded-full border border-primary-color/30 p-4 backdrop-blur-md">
                                                  <FaFirefox
                                                    className="animate-[spin_100s_linear_infinite] text-secondary-color-light h-6 w-6"
                                                    data-v-1cd58794=""
                                                  />
                                                </span>
                                                <span className="relative circle rounded-full border border-primary-color/30 p-4 backdrop-blur-md">
                                                  <FaOpera
                                                    className="animate-[spin_100s_linear_infinite] text-secondary-color-light h-6 w-6"
                                                    data-v-1cd58794=""
                                                  />
                                                </span>
                                                <span className="relative circle rounded-full border border-primary-color/30 p-4 backdrop-blur-md">
                                                  <FaInternetExplorer
                                                    className="animate-[spin_100s_linear_infinite] text-secondary-color-light h-6 w-6"
                                                    data-v-1cd58794=""
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="grid md:col-span-6">
                <div className={styles.cardContainer}>
                  <AppCard1
                    icon={<CiGlobe className="text-3xl text-white" />}
                    title={"Open-Source"}
                    description={
                      "Built on top of open-source technologies and contributions."
                    }
                    descWidth="w-3/4"
                    content={
                      <img
                        alt=""
                        src={globe}
                        className="max-w-none border border-secondary-color-light/10 h-[560px] w-[560px] brightness-125 bg-gradient-to-tr from-secondary-color-light/5 via-transparent to-secondary-color-light/5 shadow-[0_0_64px_32px_theme(colors.zinc.900)_inset] mt-80 rounded-full animate-[spin_110s_linear_infinite]"
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.gridColumns3}>
            {AI_PoweredFeatures.map((data, index) => (
              <div className={styles.cardPadding} key={index}>
                <AppCard3
                  icon={<data.icon className="text-white" />}
                  title={data.title}
                  description={data.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppSetupTest;
