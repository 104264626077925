import {
  AiOutlineCode,
  AiOutlinePullRequest,
  AiOutlineSafetyCertificate,
} from "react-icons/ai";
import { BsBoxSeam } from "react-icons/bs";
import { CiFilter, CiLaptop } from "react-icons/ci";
import { GoLock, GoPeople } from "react-icons/go";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { IoIosCode } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import { LuCloudy, LuHistory, LuPalette } from "react-icons/lu";
import {
  MdOutlineDescription,
  MdTranslate,
  MdWorkspacesOutline,
} from "react-icons/md";
import { PiStack, PiWaves } from "react-icons/pi";
import { RiCommandLine } from "react-icons/ri";
import { RxLightningBolt } from "react-icons/rx";
import {
  TbArrowsDownUp,
  TbBucket,
  TbSettings,
  TbWavesElectricity,
} from "react-icons/tb";
import { TiArrowShuffle, TiFlowMerge } from "react-icons/ti";
import { Link } from "react-router-dom";
import { appDownloads } from "../routes/routes";

export const appTestimonialsData = [
  {
    img: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710930295/60898953_wzrqbs.png",
    name: "Ritesh Bucha",
    testimonial:
      "AutoFlow helped us achieve more than 80% test coverage in a day.",
    company: " Frontend Lead, Flam",
  },
  {
    img: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710930004/1692240502063_naksid.jpg",
    name: "Sidharth Sahoo",
    testimonial: "Finally something that makes TDD a reality.",
    company: "Founder, Enitiate",
  },
  {
    img: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710929939/1678781376730_rgibys.jpg",
    name: "Sudeep Sharma",
    testimonial: "Our team loves Autoflow. Saved us a lot of time.",
    company: "Founder, Skille AI",
  },
];

export const AI_PoweredFeatures = [
  {
    title: "Lightweight and Snappy",
    icon: RxLightningBolt,
    description:
      "Designed to be seamless and instant. 150ms interactions and animations.",
  },
  {
    title: "Built for Collaboration",
    icon: GoPeople,
    description:
      "Collaborate with your team in real-time. Share user journeys and flows.",
  },
  {
    title: "Highly Customizable",
    icon: LuPalette,
    description: "Fine-grained controls to design user journeys",
  },
  {
    title: "Filter and Sort",
    icon: CiFilter,
    description:
      "See only what you need. Filter and sort buckets and journeys.",
  },
  {
    title: "Cross-Browser Apps",
    icon: BsBoxSeam,
    description: "Works on Mac, Windows and Linux. Take your work anywhere.",
  },
  {
    title: "Safe and Secure",
    icon: AiOutlineSafetyCertificate,
    description: "Designed to keep your data safe and protected.",
  },
];

export const AppFeaturesCardData = {
  firstRow: [
    {
      title: "Workspaces",
      icon: GoPeople,
      decription: "Create workspaces for your teams.",
      content: (
        <img
          src={
            "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710852548/LandingPageAssets/rx3ejor6rqbzaum3sj04.png"
          }
          className="h-auto w-auto"
          alt="drag and drop"
        />
      ),
    },
    {
      title: "Access-Level Roles",
      icon: AiOutlineSafetyCertificate,
      decription: "Control access to your workspaces.",
      content: (
        <img
          src={
            "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710852548/LandingPageAssets/yisli14isktrxb60mbax.png"
          }
          className="h-auto w-auto"
          alt="drag and drop"
          style={{
            maskImage: "bg-gradient-to-b from-black/30 to-zinc-900/40",
          }}
        />
      ),
    },
    {
      title: "Real-Time Collaboration",
      icon: RxLightningBolt,
      decription: "Control access to your workspaces.",
      content: (
        <img
          src={
            "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710852548/LandingPageAssets/mtxkled00tbh01obvggx.gif"
          }
          className="h-auto w-auto"
          alt="drag and drop"
          style={{
            maskImage: "bg-gradient-to-b from-black/30 to-zinc-900/40",
          }}
        />
      ),
    },
  ],
  secondRow: [
    {
      title: "Test Report Sharing",
      icon: TbWavesElectricity,
      decription: "Share the results of your flows runs with your teammates.",
      content: (
        <img
          src={
            "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710920791/sharableTestReportUrl_kwvd7q.png"
          }
          className="h-auto w-auto"
          alt="drag and drop"
        />
      ),
    },
    {
      title: "User Journeys & User Flows",
      icon: LuCloudy,
      decription:
        "Organize your user flows in journeys and share them with your team.",
      content: (
        <img
          src={
            "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710852548/LandingPageAssets/vmarhoupanx0rnqj5ne1.png"
          }
          className="h-64 w-64"
          alt="drag and drop"
        />
      ),
    },
  ],
  thirdRow: [
    {
      title: "Environment Variables",
      icon: PiStack,
      decription: "Manage your environment variables and use them everywhere.",
      content: (
        <img
          src={
            "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710852547/LandingPageAssets/tqv7w4mzg4dmcybfkoni.png"
          }
          className="h-64 w-64"
          alt="drag and drop"
        />
      ),
    },
    {
      title: "History",
      icon: LuHistory,
      decription: "View and manage your test run history.",
      content: (
        <img
          src={
            "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710852548/LandingPageAssets/xp0xjbnopiscj5zpkb6i.png"
          }
          className="h-auto w-auto"
          alt="drag and drop"
        />
      ),
    },
  ],
};

export const app_features_featData = [
  {
    title: "Advanced Configurations",
    icon: MdOutlineDescription,
    description:
      "Modify headers, authenticate requests, generate random data, and much more.",
  },
  {
    title: "AI Suggestions",
    icon: HiOutlineDocumentCheck,
    description:
      "Get AI Suggestions to always create high quality test buckets",
  },
  {
    title: "Multi-lingual Support",
    icon: MdTranslate,
    description:
      "AutoFlow supports testing web applications in more than 30+ languages",
  },
  {
    title: "Audit Logs",
    icon: CiFilter,
    description:
      "Track all the activities in your workspace. Who did what and when.",
  },
  {
    title: "Single-Sign-On",
    icon: GoLock,
    description: "Use your existing SSO provider to login to AutoFlow.",
  },
  {
    title: "Admin Dashboard",
    icon: AiOutlineSafetyCertificate,
    description: "Manage your users, workspaces, and more.",
  },
];

export const AppPlatformcardData = [
  {
    icon: <RxLightningBolt className="text-2xl text-white" />,
    title: "Cross-Platform Apps",
    description: "Works on Mac, Windows, Linux, Web and CLI.",
    styles: "grid md:col-span-12",
    content: (
      <>
        <div className="absolute bg-center inset-0 h-full w-full bg-[radial-gradient(theme(colors.zinc.400)1A_1px,transparent_1px)] [background-size:18px_18px]"></div>
        <Link to={appDownloads}>
          <div className="relative inline-flex items-center px-8 py-4 mx-auto overflow-hidden rounded-full transition transform bg-zinc-800/80 hover:scale-110 group cursor-pointer">
            <div className="absolute inset-0 flex items-center [container-type:inline-size]">
              <div className="absolute h-60 w-60 animate-spin bg-[conic-gradient(from_0_at_50%_50%,theme(colors.yellow.400)_0deg,transparent_60deg,transparent_300deg,theme(colors.yellow.400)_360deg)] transition duration-300 [animation-duration:3s]"></div>
            </div>
            <div className="absolute inset-0.5 rounded-full bg-zinc-950" />
            <div className="absolute bottom-0 w-4/5 rounded-full opacity-50 bg-zinc-900 transition-all duration-500 -translate-x-1/2 left-1/2 h-1/3 blur-md group-hover:h-2/3 group-hover:opacity-100"></div>
            <span className="relative text-lg font-medium text-transparent transition-all duration-200 font-display bg-gradient-to-b from-zinc-200/25 to-zinc-50 bg-clip-text">
              Download App
            </span>
          </div>
        </Link>
      </>
    ),
  },
  {
    icon: <LuCloudy className="text-2xl text-white" />,
    title: "Cloud Sync",
    description:
      "Sync your data across all your devices. Never lose your data again.",
    styles: "grid md:col-span-6",
    content: (
      <>
        <div className="lines">
          <div className="line">
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <CiLaptop className="mr-2" />
              Home
            </span>
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <PiWaves className="mr-2" />
              Flows
            </span>
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <AiOutlinePullRequest className="mr-2" />
              Requests
            </span>
          </div>
          <div className="line">
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <TbBucket className="mr-2" />
              Bucket
            </span>
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <MdWorkspacesOutline className="mr-2" />
              Workspace
            </span>
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <LuHistory className="mr-2" />
              History
            </span>
          </div>
          <div className="line">
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <TiFlowMerge className="mr-2" />
              Journeys
            </span>
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <TbSettings className="mr-2" />
              Settings
            </span>
            <span className="relative z-10 mt-4 inline-flex items-center -translate-x-1/2 rounded-md border border-secondary-color-light/10 bg-zinc-400/10 px-3 py-2 text-xs backdrop-blur-md text-secondary-color-light/90 font-display">
              <PiStack className="mr-2" />
              Environments
            </span>
          </div>
        </div>
      </>
    ),
  },
  {
    icon: <AiOutlineCode className="text-3xl text-white" />,
    title: "Run your tests on different environments",
    description:
      "With a single click, run your tests on different environments.",
    styles: "grid md:col-span-6",
    content: (
      <img
        src={
          "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1710924615/run-on-all-compressed_mbmb9m.gif"
        }
        className="h-full w-full"
        alt="drag and drop"
      />
    ),
    type:'img',
  },
];

export const app_platform_featData = [
  {
    title: "Shared Results",
    icon: IoShareSocialOutline,
    description:
      "Share the results of your test runs with your team, friends or the world.",
  },
  {
    title: "Visualise User Journeys",
    icon: IoIosCode,
    description: "Visualise the user journeys across your application.",
  },
  {
    title: "One-Click Import",
    icon: TiArrowShuffle,
    description:
      "Migrate your existing test cases from Playwright, Selenium or Cypress",
  },
  {
    title: "Regression Testing",
    icon: RiCommandLine,
    description:
      "One-click regression testing to make sure everything works as expected.",
  },
  {
    title: "Mobile Testing",
    icon: TbArrowsDownUp,
    description: "Support for countless screen types including mobile.",
  },
  {
    title: "Secure and Private",
    icon: AiOutlineSafetyCertificate,
    description: "Local-first data storage. No tracking. No analytics. No ads.",
  },
];
