import {
  IoArrowForwardSharp,
  IoCloseCircleSharp,
  IoDiamondOutline,
} from "react-icons/io5";
import { LuNewspaper, LuScrollText } from "react-icons/lu";
import { MdOutlineFileDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import { logo } from "../../../assets";
import {
  appDownloads,
  appHome,
  appPricing,
  sdkHome,
} from "../../../routes/routes";

const AppMobileNav = ({ openMenu, setOpenMenu, location }) => {
  return openMenu ? (
    <div className="absolute top-0 right-0 w-screen h-screen bg-zinc-900 flex flex-col justify-between bg-opacity-100 font-display backdrop-blur-none font-bold">
      <div>
        <div>
          <div className="flex flex-row items-center justify-between px-3">
            <Link to={appHome}>
              <div className="my-5 relative cursor-pointer">
                <img src={logo} alt="Logo" className="h-16" />
                <div class="badge absolute bg-primary-color bottom-0 right-0">Studio</div>
              </div>
            </Link>
            <button
              className="btn btn-ghost"
              onClick={() => setOpenMenu(false)}
            >
              <IoCloseCircleSharp className="text-4xl text-white" />
            </button>
          </div>
        </div>
        <div className="mt-5 flex flex-row items-center justify-end gap-x-1 mr-1">
          <Link to={sdkHome} target="_blank">
            <button
              className="btn btn-sm btn-ghost border font-display font-normal border-secondary-color-light/20 bg-secondary-color-dark text-primary-color-light/80 rounded-2xl hover:border-secondary-color-light/50
            "
            >
              SDK
            </button>
          </Link>
          <Link to={appDownloads}>
            <button
              className="btn btn-sm btn-ghost border font-display font-normal border-secondary-color-light/20 bg-primary-color-dark text-primary-color-light/80 rounded-2xl hover:border-secondary-color-light/50
            "
            >
              Download app
              <IoArrowForwardSharp />
            </button>
          </Link>
        </div>
        <div className="flex flex-col items-start pt-5">
          <Link to={appDownloads} onClick={() => setOpenMenu(false)}>
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal  ${
                location === appDownloads ? " text-yellow-600" : "text-white/50"
              }`}
            >
              <MdOutlineFileDownload /> Download
            </button>
          </Link>
          <Link>
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal cursor-not-allowed text-white/50 `}
            >
              <LuNewspaper /> Blog{" "}
              <span className="ml-2 mt-0.5 text-xs border border-secondary-color-light/10 rounded-md px-1">
                Soon!
              </span>
            </button>
          </Link>
          <Link>
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal text-white/50 `}
            >
              <LuScrollText /> Changelog{" "}
              <span className="ml-2 mt-0.5 text-xs border border-secondary-color-light/10 rounded-md px-1">
                Soon!
              </span>
            </button>
          </Link>
          <Link to={appPricing} onClick={() => setOpenMenu(false)}>
            <button
              className={`btn btn-sm btn-ghost text-base font-display font-normal  ${
                location === appPricing ? " text-yellow-600" : "text-white/50"
              }`}
            >
              <IoDiamondOutline /> Pricing
            </button>
          </Link>
        </div>
      </div>
    </div>
  ) : null;
};

export default AppMobileNav;
